<template>
  <div class="position-relative chart-interaction-comment">
    <div class="d-flex justify-content-between align-items-center chart-interaction-comment-header ml-5 mb-4">
      <div class="d-flex align-items-center">
        <p class="chart-name mr-5 mt-0 mb-0">秒数絞り込み</p>
        <i class="icon icon-interaction-chart checked"></i>
        <p class="chart-type interaction-text mr-3 ml-1 mt-0 mb-0">リアクション数の推移</p>
        <i class="icon icon-comment-chart"></i>
        <p class="chart-type ml-1 mt-0 mb-0">コメント数</p>
      </div>
      <div class="d-flex">
        <i v-if="prevPartEnabled" data-cdai="action" @click="prevPart" class="icon icon-prev cursor-pointer"
           :class="[nextPartEnabled ? 'mr-3' : 'mr-14']"></i>
        <i v-if="nextPartEnabled" data-cdai="action" @click="nextPart" class="icon icon-next mr-3 cursor-pointer"></i>
      </div>
    </div>
    <div class="position-relative line-chart-custom mr-1 ml-5">
      <highcharts
          id="chart-container"
          class="mt-2"
          :options="chartOptions"
          ref="lineChart"
          :constructor-type="'chart'"
      ></highcharts>
    </div>
    <div class="line-chart-footer pr-1 pl-5">
      <highcharts
          id="chart-footer"
          :options="footerChartOptions"
          ref="lineChart"
          :constructor-type="'chart'"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import {Chart} from "highcharts-vue";
import EventBus from "@/commons/event_bus";
import i18n from "@/i18n";
const jQuery = require("jquery");

export default {
  props: ['videoDetail', 'videoImage', 'reportVideo', 'comments', 'allComments'],
  components: {
    highcharts: Chart
  },
  data() {
    return {
      secondsSelected: [],
      chartOptions: {},
      footerChartOptions: {},
      chartData: [],
      page: 1,
      startSecond: 0,
      endSecond: 15,
      secondInteractionData: [],
      secondInteractionTotalData: {},
    }
  },
  watch: {
    comments() {
      this.formatFooterChartOptions()
    },
    startSecond() {
      this.formatFooterChartOptions()
    },
    secondsSelected(val) {
      this.setChartDataZones()
      EventBus.$emit('updateSceneComment', val)
      let valIgnoreOther = val.filter(function (el) {
        return el <= this.videoImage.duration;
      }, this);
      this.secondTotalInteraction(valIgnoreOther)
    },
    page(val) {
      this.endSecond = val * 15
      this.startSecond = this.endSecond - 15
      this.chartOptions.series[0].pointStart = this.startSecond
      this.setChartData()
    },
    reportVideo() {
      this.setChartData()
    },
    videoImage: function () {
      this.setChartDataLabels()
    }
  },
  computed: {
    nextPartEnabled: function () {
      let max = this.$props.videoImage ? this.$props.videoImage.duration + 1 : 0;
      return max > this.page * 15
    },
    prevPartEnabled: function () {
      return this.page > 1
    },
  },
  methods: {
    nextPart() {
      this.page += 1;
    },
    prevPart() {
      this.page -= 1;
    },
    setChartData() {
      this.chartData = []
      if (Object.keys(this.reportVideo).length && this.reportVideo.tally_result) {
        for (let i = this.startSecond; i <= this.endSecond; i++) {
          if (this.reportVideo.tally_result[i]) {
            if(this.videoDetail.deliver_period_type == 2) {
              this.chartData.push(Math.abs(this.reportVideo.tally_result[i].left) + Math.abs(this.reportVideo.tally_result[i].right))
            } else {
              this.chartData.push(Math.abs(this.reportVideo.tally_result[i].btn1) + Math.abs(this.reportVideo.tally_result[i].btn2) + Math.abs(this.reportVideo.tally_result[i].btn3))
            }
          } else if (i <= this.videoImage.duration + 1) {
            this.chartData.push(0)
          } else {
            this.chartData.push(null)
          }
        }
        this.chartOptions.series[0].data = this.chartData

        if(this.checkOneValueArray(this.chartData)) {
          this.chartOptions.yAxis.min = 0
        } else {
          this.chartOptions.yAxis.min = -0.1
        }
      }
      this.setChartDataLabels()

      this.$nextTick(function () {
        jQuery('.marker-custom').attr('data-cdai', 'action')
      })
    },
    setChartDataLabels() {
      let vm = this
      if (vm.videoImage) {
        const secondsSelected = vm.secondsSelected.map(Number)
        this.chartOptions.series[0].dataLabels.formatter = function () {
          if (this.x <= vm.videoImage.duration) {
            let urlImage = vm.videoImage.image_url + this.x + '.jpg'
            let arr = vm.secondsSelected.map(Number)
            const index = arr.indexOf(this.x)
            if (index > -1) {
              if (this.x === vm.endSecond && vm.nextPartEnabled) {
                if (secondsSelected.includes(this.x) && secondsSelected.includes(this.x + 1)) {
                  return '<div id="' + this.x + '" class="marker-custom marker-custom-checked" style="background-image: url(' + urlImage + ')"><div class="line-join line-join-checked"></div></div>'
                }
                return '<div id="' + this.x + '" class="marker-custom marker-custom-checked" style="background-image: url(' + urlImage + ')"><div class="line-join"></div></div>'
              }
              return '<div id="' + this.x + '" class="marker-custom marker-custom-checked" style="background-image: url(' + urlImage + ')"></div>'
            } else {
              if (this.x === vm.endSecond && vm.nextPartEnabled) {
                return '<div id="' + this.x + '" class="marker-custom" style="background-image: url(' + urlImage + ')"><div class="line-join"></div></div>'
              }
              return '<div id="' + this.x + '" class="marker-custom" style="background-image: url(' + urlImage + ')"></div>'
            }
          } else if (this.x == vm.videoImage.duration + 1) {
            if (secondsSelected.includes(this.x)) {
              return '<div id="' + this.x + '" class="marker-custom marker-custom-checked" style="background-color: #999"></div>'
            } else {
              return '<div id="' + this.x + '" class="marker-custom" style="background-color: #999"></div>'
            }
          } else return ''
        }
        this.setChartDataZones()
      }
    },
    setChartDataZones() {
      let vm = this
      let zonesData = []
      for (let i = 0; i <= this.videoImage.duration + 1; i++) {
        zonesData.push({
          value: i,
          color: '#EBEBEB'
        })
      }
      let arr = this.secondsSelected.map(Number)
      zonesData.forEach(item => {
        if (item.value == 0 && arr.includes(item.value)) item.color = '#49AB94'
        else if (arr.includes(item.value) && arr.includes(item.value - 1)) item.color = '#49AB94'
        else item.color = '#EBEBEB'
      })
      this.chartOptions.series[0].zones = zonesData
      this.chartOptions.xAxis.labels.formatter = function () {
        const index = arr.indexOf(this.value)
        if (index > -1) {
          if(this.value == vm.videoImage.duration + 1) {
            return "<div id='label-" + this.value + "' class='label-checked'>etc"
                + "<div class='tooltip-content'>" +
                      "<i class='icon icon-tooltip'></i>" +
                      "<span class='tooltip-text'>"+ i18n.t("texts.tooltip.etcComment") +"</span>" +
                  "</div></div>"
          } else {
            return "<div id='label-" + this.value + "' class='label-checked'>" + this.value + "</div>"
          }
        } else if (this.value <= vm.videoImage.duration) {
          return "<div id='label-" + this.value + "'>" + this.value + "</div>"
        } else if (this.value == vm.videoImage.duration + 1) {
          return "<div id='label-" + this.value + "'>etc"
                + "<div class='tooltip-content'>" +
                    "<i class='icon icon-tooltip'></i>" +
                    "<span class='tooltip-text'>"+ i18n.t("texts.tooltip.etcComment") +"</span>" +
                  "</div></div>"
        } else return ''
      }
    },
    jqueryDocumentReady() {
      let vm = this
      jQuery(document).on('click', '.marker-custom', function () {
        const secondSelected = jQuery(this).attr("id")
        const index = vm.secondsSelected.indexOf(secondSelected)
        if (index > -1) {
          vm.secondsSelected.splice(index, 1)
          jQuery(this).removeClass('marker-custom-checked')
          jQuery('#label-' + secondSelected).removeClass('label-checked')
        } else {
          vm.secondsSelected.push(secondSelected)
          jQuery(this).addClass('marker-custom-checked')
          jQuery('#label-' + secondSelected).addClass('label-checked')
        }
      })
    },
    checkOneValueArray(array) {
      // remove null value
      array = array.filter(function (el) {
        return el != null;
      });
      let sameValue = array.reduce(function(a, b){ return (a === b) ? a : NaN; })
      return !isNaN(sameValue) || sameValue === 0 && array.length
    },
    secondTotalInteraction(val){
      this.secondInteractionData = []
      this.secondInteractionTotalData = []
      let total_2 = 0
      let total_3 = 0
      let total_4 = 0
      if(this.reportVideo.tally_result){
        for(let i = 0; i < val.length; i++){
          if (!this.reportVideo.tally_result[val[i]]) continue
          this.secondInteractionData.push(this.reportVideo.tally_result[val[i]])
        }
        for(let i = 0; i < this.secondInteractionData.length; i++){
          if(this.secondInteractionData[i] && (this.secondInteractionData[i].left !== undefined)){
            total_2 += this.secondInteractionData[i].left;
            total_3 += Math.abs(this.secondInteractionData[i].right);
          }
          if(this.secondInteractionData[i].btn1 != undefined){
            total_2 += this.secondInteractionData[i].btn1;
            total_3 += this.secondInteractionData[i].btn2;
            total_4 += this.secondInteractionData[i].btn3;
          }
        }
        if(this.secondInteractionData.length){
          if(this.secondInteractionData[0].btn1 != undefined){
            this.secondInteractionTotalData = {
              total_2: total_2,
              total_3: total_3,
              total_4: total_4
            }
          } else {
            this.secondInteractionTotalData = {
              total_2: total_2,
              total_3: total_3,
            }
          }
        }
        if(val.length == 0){
          if(this.reportVideo.total_4 || this.reportVideo.total_4 == 0){
            this.secondInteractionTotalData = {
              total_2: this.reportVideo.total_2, 
              total_3: this.reportVideo.total_3,
              total_4: this.reportVideo.total_4,
            }
          } else {
            this.secondInteractionTotalData = {
              total_2: this.reportVideo.total_2, 
              total_3: this.reportVideo.total_3,
            }
          }
        }
      }
      this.$emit('secondInteractionTotalData', this.secondInteractionTotalData)
    },
    formatFooterChartOptions() {
      let vm = this
      let scenes = this.allComments && Object.keys(this.allComments).length ? this.allComments.scene : {}
      this.footerChartOptions.xAxis.labels.formatter = function () {
        let commentAmount = scenes[this.value + vm.startSecond] ?? 0
        if (this.value + vm.startSecond > vm.videoImage.duration + 1) {
          return ""
        }
        return "<span id='footer-label-" + this.value + "'>" + commentAmount + "</span>"
      }
    }
  },
  mounted() {
    this.jqueryDocumentReady()
    this.setChartData()
    setTimeout(() => {
      this.formatFooterChartOptions()
    }, 300);
  },
  created() {
    let vm = this
    let zonesData = [], chartData = []
    for (let i = this.startSecond; i <= this.endSecond; i++) {
      zonesData.push({
        value: i,
        color: '#EBEBEB'
      })
      chartData.push(0)
    }
    this.chartOptions = {
      chart: {
        height: '125px',
        marginRight: window.innerWidth > 1600 ? 15 : 20,
      },
      title: {
        text: undefined
      },
      tooltip: {
        enabled: false
      },
      color: '#FF0000',
      xAxis: {
        height: '48px',
        tickInterval: 1,
        labels: {
          style: {
            color: "#999999",
            cursor: "default",
            fontSize: "8px",
            fontWeight: 500,
            lineHeight: "12px",
            zIndex: 1
          },
          useHTML: true,
          formatter: function () {
            return "<span id='label-" + this.value + "'>" + this.value + "</span>"
          }
        }
      },
      yAxis: {
        visible: false,
        startOnTick: false,
      },
      series: [{
        data: chartData,
        pointStart: 0,
        color: '#EBEBEB',
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            if (vm.videoImage) {
              let urlImage = vm.videoImage.image_url + this.x + '.jpg'
              if (this.x === vm.endSecond && vm.nextPartEnabled) {
                return '<div id="' + this.x + '" class="marker-custom" style="background-image: url(' + urlImage + ')"><div class="line-join"></div></div>'
              }
              return '<div id="' + this.x + '" class="marker-custom" style="background-image: url(' + urlImage + ')"></div>'
            }
            return ''
          },
          y: 10
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        states: {
          hover: {
            enabled: true,
            lineWidth: 2
          }
        },
        zoneAxis: 'x',
        zones: zonesData
      }]
    }

    this.footerChartOptions = {
      chart: {
        height: '30px',
        marginRight: window.innerWidth > 1600 ? 15 : 20,
      },
      title: {
        text: undefined
      },
      tooltip: {
        enabled: false
      },
      color: '#FFFFFF',
      xAxis: {
        opposite: true,
        tickInterval: 1,
        labels: {
          style: {
            color: "#333333",
            cursor: "default",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px"
          },
          formatter: function () {
            return "<span id='footer-label-" + this.value + "'>0</span>"
          }
        }
      },
      yAxis: {
        visible: false
      },
      series: [{
        data: chartData,
        dataLabels: {
          enabled: false
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        states: {
          hover: {
            enabled: false
          }
        }
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.chart-interaction-comment {
  height: 175px;
  padding-top: 20px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
  border-radius: 8px;

  .cursor-pointer {
    cursor: pointer;
  }

  .chart-interaction-comment-header {
    height: 29px;
  }

  .chart-name {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #333333;
  }

  .chart-type {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #333333;
    cursor: default;
  }

  .interaction-text {
    color: #49AB94;
  }
}

::v-deep {
  .highcharts-xaxis, .highcharts-no-tooltip, .highcharts-credits {
    display: none;
  }

  .line-chart-custom {
    .label-checked {
      font-weight: 700;
      color: #49AB94;
      fill: #49AB94;
    }

    .marker-custom {
      position: absolute;
      top: -18px;
      left: -14px;
      width: 26px;
      height: 26px;
      border: 2px solid #999999;
      border-radius: 15px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;

      &::after {
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('/images/svg/icon_checkbox_second_chart.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .line-join {
        position: absolute;
        width: 15px;
        height: 2px;
        background-color: #EBEBEB;
        right: -17px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .marker-custom-checked {
      border-color: #49AB94;

      &::after {
        background-image: url('/images/svg/icon_checkbox_checked_second_chart.svg');
      }

      .line-join-checked {
        background-color: #49AB94;
      }
    }
  }

  .line-chart-footer {
    margin-top: -25px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #EBEBEB;
  }

  #chart-footer {
    .highcharts-background {
      fill: transparent;
    }

    div.highcharts-container {
      background-color: #EBEBEB;
    }
  }

  #chart-container {
    overflow: unset !important;
    div.highcharts-container {
      overflow: unset !important;
    }
  }

  .tooltip-content {
    margin-left: 5px;
    transform: translateY(5px);

    .tooltip-text {
      margin-left: -36px;
    }
  }

}
</style>