<template>
  <div class="text-box form-group" :class="classEl" :id="idTextBox">
    <span class="top-title" v-if="topTitle.length">{{ topTitle }}</span>
    <textarea
      :style="{ height: setHeight + 'px' }"
      class="form-control"
      :placeholder="placeholder"
      v-model="contents"
      @change="changeContents()"
      v-bind:[dataCustom]="'action'"
    >
    </textarea>
    <div class="tooltip-input">
      <ToolTip :text="formattedToolTipText"></ToolTip>
    </div>
    <div class="pull" v-show="resizeFlag"></div>
    <div class="open-ai-image">
      <i class="icon icon-gpt"></i>
    </div>
    <div class="tooltip-user" :id="idTextBox + '-tooltip'" v-if="updateUserName">
      {{ '最終編集者: ' + updateUserName }}
    </div>
  </div>
</template>
<script>
import ToolTip from '@/components/Commons/ToolTip.vue'

const jQuery = require('jquery')
export default {
  components: { ToolTip },
  props: {
    videoId: Number,
    urlGet: String,
    urlPost: String,
    placeholder: String,
    classTextBox: String,
    idTextBox: {
      type: String,
      default: 'text-box',
    },
    isSetMargin: {
      type: Boolean,
      default: false,
    },
    resizeFlag: {
      type: Boolean,
      default: true,
    },
    topTitle: {
      type: String,
      default: '',
    },
    setHeight: {
      type: Number,
      default: 90,
    },
    dataCustom: {
      type: String,
      default: '',
    },
    textToolTip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contents: '',
      updateUserName: '',
      currentUser: this.$store.getters.currentUser,
      observer: null,
      fetchedContents: '',
    }
  },
  watch: {
    videoId: function () {
      this.getVideoTopTabular()
    },
  },
  computed: {
    classEl: function () {
      let classText = this.classTextBox
      if (this.topTitle.length) {
        classText += ' has-title'
      }
      return classText
    },
    formattedToolTipText() {
      const rawText = this.textToolTip ? this.textToolTip : this.$t('texts.tooltip.detail');
      return rawText.replace(/\n/g, '<br>');
    }
  },
  mounted() {
    let vm = this
    let KeyDown
    const textBoxEl = jQuery(`.${vm.classTextBox}`)
    jQuery(`.${this.classTextBox} > .pull`).mousedown(function () {
      jQuery(this).parent().addClass('Resize UnSelectable')
      KeyDown = 1
    })
    jQuery(document).mouseup(function () {
      jQuery(`.${vm.classTextBox}`).removeClass('Resize UnSelectable')
      KeyDown = 0
    })
    jQuery(document).mousemove(function (Event) {
      if (KeyDown == 1 && textBoxEl.hasClass('Resize')) {
        let Height = Event.pageY - jQuery(`.${vm.classTextBox}`).children('textarea').offset().top
        jQuery(`.${vm.classTextBox} > textarea`).height(Height)
      }
    })
    this.setMargin()
    this.initTooltipContent()

    // Initialize the Intersection Observer
    this.initIntersectionObserver()
  },
  methods: {
    changeContents() {
      this.createVideoTopTabular()
    },
    //api
    getVideoTopTabular() {
      let params = {
        video_id: this.videoId,
      }
      let url = this.$router.resolve({
        name: this.urlGet,
      }).href
      this.$http2.get(
        url,
        params,
        data => {
          this.updateUserName = data.update_user_name
          this.fetchedContents = data.contents
        },
        error => {
          console.log(error)
        },
      )
    },
    createVideoTopTabular() {
      let params = {
        video_id: this.videoId,
        user_id: this.currentUser.user_id,
        contents: this.contents,
      }
      let url = this.$router.resolve({
        name: this.urlPost,
      }).href
      this.$http2.post(
        url,
        params,
        () => {
          this.getVideoTopTabular()
        },
        error => {
          console.log(error)
        },
      )
    },
    setMargin() {
      let element = document.getElementById(this.idTextBox)
      let segment = document.getElementsByClassName('group-list-segment')
      if (segment.length == 0 && this.isSetMargin) {
        element.style.marginBottom = '44px'
      }
    },
    initTooltipContent() {
      let container = jQuery('body')
      let tooltipSelector = `#${this.idTextBox + '-tooltip'}`
      let textBoxSelector = `#${this.idTextBox}`

      container.on('mouseover', textBoxSelector, function () {
        jQuery(tooltipSelector).css('visibility', 'visible').css('opacity', '1')
      })

      container.on('mouseout', textBoxSelector, function () {
        jQuery(tooltipSelector).css('visibility', 'hidden').css('opacity', '0')
      })
    },
    initRenderAnimationText(contents) {
      const textArea = jQuery(`#${this.idTextBox}`)
      let index = 0
      let isRendering = true

      const renderText = () => {
        if (index < contents.length && isRendering) {
          this.contents += contents[index]
          index++
        } else {
          clearInterval(interval)
        }
      }

      const interval = setInterval(renderText, 25)

      textArea.on('click', () => {
        if (isRendering) {
          clearInterval(interval)
          isRendering = false
          this.contents = contents
        }
      })
    },
    initIntersectionObserver() {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }

      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.startTextAnimation()
            this.observer.unobserve(entry.target)
          }
        })
      }, observerOptions)

      const target = document.getElementById(this.idTextBox)
      if (target) {
        this.observer.observe(target)
      }
    },
    startTextAnimation() {
      if (this.fetchedContents) {
        this.initRenderAnimationText(this.fetchedContents)
      } else {
        setTimeout(() => {
          this.startTextAnimation()
        }, 100)
      }
    },
  },
  created() {
    if (this.videoId) this.getVideoTopTabular()
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variable';

.text-box {
  position: relative;
  display: flex;
  margin-top: 20px;
  background-color: #edf7f4;
  border: 1px solid #edf7f4;
  border-radius: 8px;

  &.form-group {
    width: 100%;
  }

  &.has-title {
    padding-top: 40px;
  }

  textarea {
    position: relative;
    resize: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    font-family: 'Avenir Next', 'Noto Sans JP', sans-serif;
    padding-bottom: 35px;

    &::placeholder {
      color: #999999;
    }
  }

  ::v-deep .tooltip-content {
    transform: translate(-21px, 7px);
    width: 0;
    height: 0;
    margin: 0 auto;

    .tooltip-text {
      margin-left: -185px;

      &::after {
        left: 186px;
      }
    }
  }

  textarea::-webkit-scrollbar {
    width: 2px;
  }

  .pull {
    height: 12px;
    width: 80px;
    background: #999999;
    border: 5px solid #edf7f4;
    box-sizing: border-box;
    line-height: 0px;
    border-radius: 12px;
    position: absolute;
    bottom: 0px;
    left: 50%;

    &:hover {
      cursor: n-resize;
    }
  }

  .top-title {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 2;
    font-size: 15px;
    font-weight: 700;
  }

  .tooltip-input {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .tooltip-user {
    visibility: hidden;
    background-color: $light-black;
    color: $green;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 3;
    top: 95%;
    left: 70%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip-user::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $light-black transparent;
  }
}

.UnSelectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.open-ai-image {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: $light-green;

  .icon-gpt {
    height: 20px;
    width: 100px;
    background-size: contain;
  }
}
</style>
