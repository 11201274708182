<template>
  <div>
    <div class="comment">
      <div v-if="!isFilter" class="top-comment">
        <div>
          <input v-model.trim="keywordSearching" placeholder="コメント検索" ref="keywordSearching" @blur="updateKeyWordComment"
                 data-cdai="action"
                @keyup.enter="$refs.keywordSearching.blur()"/>
          <p v-if="commentNotFoundMsg && !$store.getters.isLoadingApiVideoComment" class="keyword-not-found mt-1 mb-0">{{ commentNotFoundMsg }}</p>
        </div>
      </div>
      <div
          v-if="!isFilter"
          class="comment-list p-5"
          :class="[
              {'comment-list-not-found': commentNotFoundMsg},
              {'comment-list-in-summary': isCommentCsv == 3},
              {'no-intention': videoDetail.is_intention == videoIntentionStatus.disabled}
          ]"
      >
        <div class="comment-header d-flex justify-content-between align-items-center flex-wrap">
          <div class="d-flex cursor-pointer">
            <p v-if="videoAttributeId" class="filter m-0" @click="resetData" data-cdai="action">設定解除</p>
            <i v-if="videoAttributeId" class="icon icon-small-trash ml-5 mr-1" @click="resetData" data-cdai="action"></i>
            <p class="filter m-0" @click="isFilter = true" data-cdai="action"> {{ videoAttributeId ? 'セグメントの設定' : 'セグメントで絞り込む' }}</p>
            <i class="icon icon-small-filter mr-1" @click="isFilter = true" data-cdai="action"></i>
          </div>
          <p class="title m-0">コメント一覧（{{ comments.all_comment ? comments.all_comment : 0 }}件）</p>
        </div>
        <div class="comment-content">
          <div v-for="(attribute,i) in comments.comment_attribute_value" :key="i">
            <p class="comment-title">{{ attribute.comment }}</p>
            <p class="comment-desc">{{ attribute.attribute_value.replaceAll("/", " x ") }}</p>
          </div>
        </div>
      </div>
      <div v-else class="d-rtl p-5 scroll-y-custom">
        <div class="comment-header d-flex justify-content-between align-items-center">
          <div class="d-flex cursor-pointer">
            <p class="filter m-0" @click="back" data-cdai="action">コメント一覧に戻る</p>
          </div>
          <p class="title m-0">セグメントで絞り込む</p>
        </div>
        <div class="d-flex flex-wrap comment-filter mt-2">
          <div v-for="attribute in videoAttributes" :key="'attribute-' + attribute.id" class="segment-item"
              :class="{ 'segment-item-selected': videoAttributeId == attribute.id }" data-cdai="action"
              @click="selectAttribute(attribute.id)">
            {{ attribute.name }}
          </div>
        </div>
        <div v-if="videoAttributeId" class="d-flex flex-wrap align-items-center attributes mt-3">
          <div v-for="attribute_value in attributeValueSelected" :key="attribute_value.id"
              class="d-flex align-items-center attribute-item mt-3 mr-10">
            <i class="icon icon-checkbox"
              :class="{checked: attributeValueIdSelected.includes(attribute_value.id)}" data-cdai="action"
              @click="checkedAttributeValue(attribute_value.id)"></i>
            <div class="pl-2 pr-5">
              <div class="attribute-name">{{ attribute_value.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="comment-csv p-5">
        <div class="d-flex align-item-center mb-3">
          <div class="btn btn-download btn-primary-outline mr-2" @click="setIsCsvDownload()" data-cdai="action">
            <i class="icon icon-small-download mr-1"></i>
            <div>コメント一覧</div>
          </div>
          <label for="btn-csv-upload" class="btn btn-primary-outline d-flex">
              <i class="icon icon-small-upload mr-1"></i>
              <span>アップロード</span>
              <input type="file"
                     class="btn-csv-upload"
                     id="btn-csv-upload"
                     @change="uploadComment($event)"
                     accept=".csv"
                     :disabled="$props.isCsvCommentUploading"
                     ref="inputCsv"
                     data-cdai="action"
              >
          </label>
        </div>
        <div class="csv-guidance">
          コメント一覧を整理するとより正確なデータが確認できます。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/commons/event_bus";
import {
  getAttributesPromise,
  getVideoSegmentsPromise
} from "@/commons/api_service";
import { videoIntentionStatus } from "@/commons/constants";

export default {
  props: [
    'videoDetail',
    'comments',
    'keyword',
    'attributeIds',
    'attributeIdSelected',
    'commentNotFoundMsg',
    'attributeValueCommentCount',
    'isCsvCommentUploading',
    'isCommentCsv'
  ],
  data() {
    return {
      isFilter: false,
      segmentSelected: null,
      keywordSearching: this.keyword ?? '',
      videoAttributes: {},
      attributes: [],
      videoSegments: [],
      attributeValueSelected: [],
      attributeValueIdSelected: this.attributeIds ? this.attributeIds.split(',').map(Number) : [],
      isCsvDownload: true,
      commentFileUploadInfo : {
        file: null,
        file_text: null,
        file_url: null,
      },
      videoIntentionStatus,
    }
  },
  watch: {
    attributeIdSelected(newValue) {
      this.setAttributeValueSelect(newValue)
    }
  },
  computed: {
    videoAttributeId() {
      return this.attributeIdSelected
    }
  },
  methods: {
    resetData() {
      EventBus.$emit('updateAttrIdSelected', null)
      this.attributeValueSelected = []
      this.attributeValueIdSelected = []
      this.back()
    },
    back() {
      this.isFilter = false
      EventBus.$emit('updateAttrComment', this.attributeValueIdSelected)
    },
    updateKeyWordComment() {
      EventBus.$emit('updateKeyWordComment', this.keywordSearching)
    },
    checkedAttributeValue(attributeValueId) {
      let index = this.attributeValueIdSelected.indexOf(attributeValueId)
      if (index > -1) this.attributeValueIdSelected.splice(index, 1)
      else this.attributeValueIdSelected.push(attributeValueId)
    },
    selectAttribute(videoAttributeId) {
      EventBus.$emit('updateAttrIdSelected', videoAttributeId)
    },
    getAttributeDataById(attribute_id) {
      return attribute_id ? this.attributes.find(e => e.id == attribute_id) : {}
    },
    getAttributeValueDataById(attributeData, attribute_value_id) {
      return attributeData && attribute_value_id ? attributeData.values.find(e => e.id == attribute_value_id) : {}
    },
    formatAttributeVideo() {
      let videoAttributes = {}
      this.videoSegments.forEach((data) => {
        let attribute_id = data.attribute_id
        let attributeData = this.getAttributeDataById(attribute_id)
        let attribute_value_id = data.attribute_value_id
        let attributeValueData = this.getAttributeValueDataById(attributeData, attribute_value_id)
        let attr_values = {}
        if (attributeData && attributeValueData) {
          attr_values['id'] = attribute_value_id
          attr_values['value'] = attributeValueData.value
          attr_values['sequence'] = attributeValueData.sequence
          attr_values['count'] = (data.count) ? data.count : 0
          if (attribute_id in videoAttributes) {
            videoAttributes[attribute_id].attr_values.push(attr_values)
            videoAttributes[attribute_id].total_count += (data.count) ? data.count : 0
          } else {
            videoAttributes[attribute_id] = attributeData
            videoAttributes[attribute_id].attr_values = []
            videoAttributes[attribute_id].attr_values.push(attr_values)
            videoAttributes[attribute_id].total_count = 0
            videoAttributes[attribute_id].total_count += (data.count) ? data.count : 0
          }
        }
      })
      this.videoAttributes = videoAttributes
    },
    setAttributeValueSelect(attribute_id) {
      let attributeValueSelected = []
      if (attribute_id in this.videoAttributes) {
        attributeValueSelected = this.videoAttributes[attribute_id].attr_values
      }
      this.attributeValueSelected = attributeValueSelected
    },
    initData() {
      let param = {
        video_id: this.videoDetail.id,
      }
      Promise.all([
        getAttributesPromise(),
        getVideoSegmentsPromise(param, true),
      ]).then((data) => {
        this.attributes = data[0]
        this.videoSegments = data[1]
        this.formatAttributeVideo()
        this.setAttributeValueSelect(this.attributeIdSelected)
      })
    },
    setIsCsvDownload(){
      let isCsvDownload = this.isCsvDownload;
      EventBus.$emit('isCsvDownload', isCsvDownload)
    },
    uploadComment(event) {
      let vm = this;
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        this.$refs.inputCsv.value = '';
        return;
      }

      let file = files[0];
      if(file) {
        if(file?.type != 'text/csv') {
          this.$toasted.error('.csv形式のファイルのみアップロードできます')
          this.$refs.inputCsv.value = '';
        } else {
          vm.commentFileUploadInfo.file = file;
          vm.commentFileUploadInfo.file_text = file?.name;
          vm.commentFileUploadInfo.file_url = URL.createObjectURL(file);
          EventBus.$emit('uploadCsvComment', vm.commentFileUploadInfo)
          this.$refs.inputCsv.value = '';
        }
      }
    },
  },
  mounted() {
    this.initData()
  }
}
</script>
<style lang="scss" scoped>
.d-rtl {
  direction: rtl;
}

.cursor-pointer {
  cursor: pointer;
}

.scroll-y-custom {
  height: 414px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 8px;
    background-color: #EBEBEB;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333333;
  }
}

.comment {
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  height: 100%;
}

.top-comment {
  padding: 20px 20px 0;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #EBEBEB;

    input {
      color: #333333;
      height: 24px;
      padding: 14px 20px;
      border: none;
      background-color: #EDF7F4;
      border-radius: 8px;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #999999;
      }
    }

    .keyword-not-found {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #CE6060;
    }
  }
}

.comment-list {
  overflow-y: scroll;
  direction: rtl;
  height: 342px;

  &.no-intention {
    height: 302px;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: #EBEBEB;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333333;
  }


  .comment-content {
    margin-top: 26px;

    p {
      direction: ltr;
    }

    .comment-title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #333333;
      margin-top: 24px;
      margin-bottom: 0
    }

    .comment-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      color: #999999;
      margin: 0
    }
  }
}

.comment-list-in-summary{
  height: 758px;

  &.no-intention {
    height: 718px;
  }
}

@media screen and (max-width: 1400px){
  .comment-list-in-summary{
    height: 795px;

    &.no-intention {
      height: 755px;
    }
  }
}
@media screen and (min-width: 986px) and (max-width: 1160px){
  .comment-list-in-summary{
    height: 895px;

    &.no-intention {
      height: 855px;
    }
  }
}
@media screen and (max-width: 985px){
  .comment-list-in-summary{
    height: 850px;

    &.no-intention {
      height: 810px;
    }
  }
}

.comment-list-not-found {
  height: 292px;
}

.comment-header {
  .filter {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #49AB94;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
  }
}

.comment-filter {
  direction: ltr;

  .segment-item {
    width: fit-content;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: #999999;
    padding: 10px 20px;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
  }

  .segment-item-selected {
    color: #49AB94;
    border: 1px solid #49AB94;
  }
}

.attributes {
  direction: ltr;

  .attribute-item {
    flex: 0 0 calc(50% - 40px);

    .attribute-name {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      color: #333333;
    }

    .attribute-desc {
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 8px;
      color: #999999;
    }
  }
}

.comment-csv{
  height: 70px;
  border-top: 1px solid #EBEBEB;
  .btn{
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 10px;
    line-height: 16px;
    height: 30px;
    margin-top: 4px;

    div,label{
      font-size: 10px;
      color: #49AB94;
    }

    .btn-csv-upload{
    display: none;
    }

    label{
      cursor: pointer;
    }
  }

  .csv-guidance{
    font-size: 12px;
  }
}
</style>