<template>
  <div class="comment-detail w-33">
    <div class="d-flex align-items-center p-4 justify-content-between message-delivery comment-detail-inner flex-col">
      <div class="d-flex align-items-center">
        <span class="comment-detail-title">メッセージ伝達率
          <ToolTip :text="$t('texts.tooltip.brand_detail')"></ToolTip>
        </span>
      </div>
      <div class="text-center d-flex flex-col justify-content-center">
        <span class="rate">{{ messageDeliveryRate.rate }}%</span>
        <span class="top-bottom">{{ messageDeliveryRate.top }}/{{ messageDeliveryRate.bottom }}</span>
      </div>
    </div>
    <div class="d-flex align-items-center p-4 justify-content-between comment-detail-inner flex-col">
      <div class="d-flex align-items-center">
        <span class="comment-detail-title">ブランド言及率</span>
      </div>
      <div class="text-center d-flex flex-col justify-content-center">
        <span class="rate">{{ brandMentionRate.rate }}%</span>
        <span class="top-bottom">{{ brandMentionRate.top }}/{{ brandMentionRate.bottom }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import ToolTip from "@/components/Commons/ToolTip";

export default {
  props: ['comments'],
  components: {
    ToolTip
  },
  data() {
    return {
      brandMentionRate: {},
      messageDeliveryRate: {},
    }
  },
  watch: {
    comments: function () {
      this.setDetailCommentValue();
    }
  },
  methods: {
    setDetailCommentValue() {
      this.brandMentionRate = this.comments.brand_mention_rate ?? {};
      this.messageDeliveryRate = this.comments.message_delivery_rate ?? {};
    }
  },
  created() {
    this.setDetailCommentValue()
  }
}
</script>
<style lang="scss" scoped>
.comment-detail {
  border-radius: 8px;
  border: 1px solid #DDDDDD;
  display: flex;
  width: 100%;
  justify-content: space-around;

  .message-delivery {
    border-bottom: solid 1px #EBEBEB;
  }

  .comment-detail-inner {

    .comment-detail-title {
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .rate {
      font-family: Avenir Next;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 51.2px;
    }

    .top-bottom {
      font-family: Avenir Next;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: #999999;
    }
  }
}

::v-deep .tooltip-content {
  transform: translateY(3px);
}

</style>